import React from 'react'
import { graphql } from 'gatsby'

// Ours
import getCtaIdFromSelect from '../utils/get-ctaid-from-select'
import filterRelatedProjects from '../utils/filter-related-projects'
import SEO from '../components/SEO'
import HeaderAbstract from '../components/HeaderAbstract'
import MainBlocksMap from '../components/MainBlocksMap'
import { BlockWrapper } from '../components/MainBlocks'
import WPPage from './WPPage'
import RelatedProjects from '../components/RelatedProjects'
import CallToAction from '../components/CallToAction'

const ExpertiseTemplate = props => {
  const data = props.data
  const pageNode = data.wpPage

  // If it doesn’t have a parent, use a page
  if (!pageNode.wpParent) {
    return <WPPage {...props} />
  }

  let blocks = data.wpPage.main.mainContent
  let limit = 4
  let relatedProjects = filterRelatedProjects(data.related, pageNode, limit)
  let chosenCta = getCtaIdFromSelect(pageNode.details)

  return (
    <div className="WPExpertise">
      <SEO
        title={pageNode.title}
        description={pageNode.details.description}
        featuredImage={pageNode.featuredImage}
      />
      <article>
        <HeaderAbstract
          title={pageNode.title}
          subtitle={pageNode.main.subtitle || pageNode.details.description}
          titleColor="white"
          bgImage={
            pageNode.featuredImage
              ? pageNode.featuredImage.node.localFile.childImageSharp.resize.src
              : null
          }
        />
        <MainBlocksMap theme="expertise" blocks={blocks} />
      </article>
      <BlockWrapper bg="silver" maxWidth={6}>
        <RelatedProjects
          edges={relatedProjects}
          limit={limit}
          {...props.pageContext}
        />
      </BlockWrapper>
      <CallToAction ctaId={chosenCta} />
    </div>
  )
}

export default ExpertiseTemplate

export const pageQuery = graphql`
  query WordpressServicePageById($id: String!) {
    wpPage: wpExpertise(id: { eq: $id }) {
      id
      title
      slug
      modified
      content
      databaseId
      date
      slug
      title
      id
      content

      details {
        description
        ctaSelect
      }

      wpParent {
        node {
          id
          databaseId
          ... on WpExpertise {
            title
            slug
          }
        }
      }

      featuredImage {
        node {
          id
          localFile {
            extension
            childImageSharp {
              id
              fluid(maxWidth: 1280) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              resize(width: 1920, height: 1200, cropFocus: CENTER) {
                src
              }
            }
          }
        }
      }
      main {
        subtitle
        ... on WpExpertise_Main {
          mainContent {
            ... on WpExpertise_Main_MainContent_SectionHeading {
              fieldGroupName
              text
            }
            ... on WpExpertise_Main_MainContent_Text {
              fieldGroupName
              sectionHeading
              text
            }
            ... on WpExpertise_Main_MainContent_Image {
              fieldGroupName
              sectionHeading
              image {
                uri
                altText
                localFile {
                  childImageSharp {
                    id
                    fluid(maxWidth: 2560) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              captionDetails {
                detail
                fieldGroupName
                label
                labelCustom
              }
              captionText
              settings {
                showFullWidth
              }
            }
            ... on WpExpertise_Main_MainContent_TwoColumn {
              fieldGroupName
              image {
                altText
                localFile {
                  childImageSharp {
                    id
                    fluid(maxWidth: 1280) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              content {
                subhead
                text
                callToAction {
                  target
                  title
                  url
                }
              }
              settings {
                bg
                color
                sticky
              }
            }
            ... on WpExpertise_Main_MainContent_ThreeColumn {
              fieldGroupName
              sectionHeading
              columns {
                bigNumber
                image {
                  altText
                  localFile {
                    childImageSharp {
                      id
                      resize(width: 1280, height: 1280, cropFocus: CENTER) {
                        src
                      }
                    }
                  }
                }
                subhead
                text
              }
              settings {
                showBigNumbers
                showImages
                showImagesStylized
              }
            }
            ... on WpExpertise_Main_MainContent_PartnerLogos {
              fieldGroupName
              sectionHeading
              partnerLogos {
                altText
                id
                localFile {
                  childImageSharp {
                    id
                    fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
            ... on WpExpertise_Main_MainContent_Testimonials {
              fieldGroupName
              testimonials {
                author
                organization
                quote
              }
            }
            ... on WpExpertise_Main_MainContent_Project {
              fieldGroupName
              project {
                ... on WpProject {
                  title
                  uri
                  projectDetails {
                    projectStatus
                  }
                  details {
                    description
                  }
                  featuredImage {
                    node {
                      id
                      localFile {
                        extension
                        childImageSharp {
                          id
                          fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                  featuredImagePortrait {
                    featuredMediaPortrait {
                      localFile {
                        extension
                        childImageSharp {
                          id
                          fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on WpExpertise_Main_MainContent_Gallery {
              captionText
              settings {
                showFullWidth
              }
              fieldGroupName
              sectionHeading
              gallery {
                altText
                localFile {
                  extension
                  childImageSharp {
                    id
                    fluid(maxWidth: 2560) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    # Limit projects to completed, not same id
    # TODO Right now still doing this client-side,
    # Should be able to limit to project_relatedexpertise, etc.
    # with proper GraphQL fragment
    related: allWpProject(
      filter: {
        id: { ne: $id }
        projectDetails: { projectStatus: { eq: "completed" } }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          uri
          details {
            description
          }
          projectDetails {
            location
            projectRelatedexpertise {
              ... on WpExpertise {
                # databaseId
                parentId
                title
                slug
              }
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  id
                  resize(width: 1280, height: 1280, cropFocus: CENTER) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
